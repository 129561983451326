<script lang="ts" setup>
const { right } = useSlots()
</script>

<template>
  <header
    class="
        fixed
        top-0
        z-40
        flex
        h-[--header-height]
        w-screen
        items-center
        gap-4
        px-4
        py-3
        shadow-[0_1px_4px_0px_rgba(0,0,0,0.1)]
        "
    :class="{
      'justify-between': !!right,
    }"
  >
    <div class="flex items-center gap-2">
      <slot />
    </div>
    <div
      v-if="right"
      class="flex items-center gap-2"
    >
      <slot name="right" />
    </div>
  </header>
</template>
