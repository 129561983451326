<script lang="ts" setup>

</script>

<template>
  <div class="min-h-screen">
    <NuxtLoadingIndicator />
    <TheHeader>
      <slot name="header" />
      <template #right>
        <slot name="header-right" />
      </template>
    </TheHeader>
    <main
      class="
          absolute
          inset-x-0
          top-[--header-height]
          h-0
          min-h-[calc(100vh-var(--header-height))]
          "
    >
      <slot />
    </main>
  </div>
</template>
